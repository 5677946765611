.light-theme {
  --primary-color: #241d1c;
  --secondary-color: #ffffff;
  --primary-text-color: #141519;
  --secondary-text-color: #6b6f80;
  --container-color: #ffffff;
  --card-color: #f6f7f9;
  --border-radius: 0.5rem;
  --widget-border-radius: 1rem;
  --font-family: "Inter", sans-serif;

  /* widget styles */
  --background-color: var(--container-color);
  --overlay-backdrop-color: rgba(15, 10, 36, 0.4);
  --drawer-bg-color: var(--container-color);
  --onramp-info-bg-color: var(--card-color);
  --onramp-info-border-color: #bbbfcc;
  --onramp-info-section-bg: var(--card-color);
  --onramp-info-section-error-color: #e62e2e;

  /* oui component styles */
  --oui-btn-primary-border-radius: var(--border-radius);
  --oui-btn-secondary-border-radius: 3rem;
  --oui-btn-primary-color: #ffffff;
  --oui-btn-primary-bg: var(--primary-color);
  --oui-btn-primary-bg-hover: #00de89;
  --oui-btn-primary-disabled-color: #9ea2b3;
  --oui-btn-primary-disabled-bg: #edeef2;
  --oui-btn-secondary-color: var(--primary-text-color);
  --oui-btn-secondary-bg: #ffffff;
  --oui-btn-secondary-disabled-color: #9ea2b3;
  --oui-btn-secondary-disabled-bg: #edeef2;
  --oui-btn-secondary-border-color: #e6dcdc;
  --oui-btn-secondary-border-color-hover: #9ea2b3;
  --oui-icon-btn-color: var(--primary-text-color);
  --oui-icon-btn-border-color: #e6dcdc;
  --oui-icon-btn-border-radius: var(--border-radius);
  --oui-icon-btn-selected-border-color: var(--primary-color);
  --oui-input-text-color: var(--primary-text-color);
  --oui-input-border-radius: var(--border-radius);
  --oui-input-border-color: #bbbfcc;
  --oui-input-error-border-color: #e62e2e;
  --oui-input-hover-border-color: #838799;
  --oui-input-focus-border-color: var(--primary-color);
  --oui-banner-success-bg: #e6fff3;
  --oui-banner-danger-bg: #ffe6e6;
  --oui-banner-title-color: var(--primary-text-color);
  --oui-banner-body-color: #6b6f80;
  --oui-menu-bg: var(--background-color);
  --oui-menu-color: var(--primary-text-color);
  --oui-menu-border-radius: var(--widget-border-radius);
  --oui-switch-color: #ffffff;
  --oui-switch-on-bg-color: #00de89;
  --oui-switch-off-bg-color: #bbbfcc;
  --oui-tooltip-color: #fafaff;
  --oui-tooltip-bg-color: #00de89;
  --oui-list-item-hover-color: #fafaff;
  --oui-list-item-rounded-border-color: #edeef2;
  --oui-list-item-rounded-border-hover-color: #bbbfcc;
  --oui-list-item-rounded-border-selected-color: var(--primary-color);
  --oui-list-item-primary-text-color: var(--primary-text-color);
  --oui-list-item-secondary-text-color: #6b6f80;
  --oui-list-item-tertiary-text-color: #6b6f80;
  --oui-list-item-top-label-color: #6affc5;
  --oui-list-item-top-label-text-color: var(--primary-text-color);
  --oui-currency-card-bg: var(--card-color);
  --oui-currency-card-border-radius: var(--border-radius);
  --oui-currency-card-hover-border-color: #bbbfcc;
  --oui-currency-card-label-text-color: var(--secondary-text-color);
  --oui-currency-card-input-text-color: var(--primary-text-color);
  --oui-currency-card-button-selected-color: var(--primary-text-color);
  --oui-currency-card-button-selected-bg: var(--secondary-color);
  --oui-header-text-color: var(--primary-text-color);
  --oui-header-tab-option-color: var(--primary-text-color);
  --oui-header-tab-option-selected-color: var(--primary-text-color);
  --oui-header-tab-option-stroke-color: #edeef2;
  --oui-header-tab-option-selected-stroke-color: #00de89;
  --oui-skeleton-color: #c2cfd6;
}

.dark-theme {
  --primary-color: #46de8c;
  --secondary-color: #3f3f43;
  --primary-text-color: #ffffff;
  --secondary-text-color: #ffffff;
  --container-color: #141416;
  --card-color: #272727;
  --border-radius: 0.5rem;
  --widget-border-radius: 1rem;
  --font-family: "Inter", sans-serif;

  /* widget styles */
  --background-color: var(--container-color);
  --overlay-backdrop-color: rgba(0, 0, 0, 0.7);
  --drawer-bg-color: var(--container-color);
  --onramp-info-bg-color: var(--card-color);
  --onramp-info-border-color: #767676;
  --onramp-info-section-bg: var(--card-color);
  --onramp-info-section-error-color: #e62e2e;

  /* oui component styles */
  --oui-btn-primary-border-radius: var(--border-radius);
  --oui-btn-secondary-border-radius: 3rem;
  --oui-btn-primary-color: #1d211e;
  --oui-btn-primary-bg: var(--primary-color);
  --oui-btn-primary-bg-hover: #44d687;
  --oui-btn-primary-disabled-color: #767676;
  --oui-btn-primary-disabled-bg: var(--card-color);
  --oui-btn-secondary-color: var(--primary-text-color);
  --oui-btn-secondary-bg: var(--background-color);
  --oui-btn-secondary-disabled-color: #9ea2b3;
  --oui-btn-secondary-disabled-bg: #edeef2;
  --oui-btn-secondary-border-color: #e6dcdc;
  --oui-btn-secondary-border-color-hover: #767676;
  --oui-icon-btn-color: var(--primary-text-color);
  --oui-icon-btn-border-color: #767676;
  --oui-icon-btn-border-radius: var(--border-radius);
  --oui-icon-btn-selected-border-color: var(--primary-color);
  --oui-input-text-color: var(--primary-text-color);
  --oui-input-border-radius: var(--border-radius);
  --oui-input-border-color: #bbbfcc;
  --oui-input-error-border-color: #e62e2e;
  --oui-input-hover-border-color: #838799;
  --oui-input-focus-border-color: var(--primary-color);
  --oui-banner-success-bg: #e6fff3;
  --oui-banner-danger-bg: #ffe6e6;
  --oui-banner-title-color: #141519;
  --oui-banner-body-color: #6b6f80;
  --oui-menu-bg: var(--background-color);
  --oui-menu-color: var(--primary-text-color);
  --oui-menu-border-radius: var(--widget-border-radius);
  --oui-switch-color: #ffffff;
  --oui-switch-on-bg-color: #00de89;
  --oui-switch-off-bg-color: #bbbfcc;
  --oui-tooltip-color: #bbbfcc;
  --oui-tooltip-bg-color: #00de89;
  --oui-list-item-hover-color: var(--card-color);
  --oui-list-item-rounded-border-color: #767676;
  --oui-list-item-rounded-border-hover-color: #bbbfcc;
  --oui-list-item-rounded-border-selected-color: var(--primary-color);
  --oui-list-item-primary-text-color: var(--primary-text-color);
  --oui-list-item-secondary-text-color: var(--primary-text-color);
  --oui-list-item-tertiary-text-color: var(--primary-text-color);
  --oui-list-item-top-label-color: #6affc5;
  --oui-list-item-top-label-text-color: #1d211e;
  --oui-currency-card-bg: var(--card-color);
  --oui-currency-card-border-radius: var(--border-radius);
  --oui-currency-card-hover-border-color: #767676;
  --oui-currency-card-label-text-color: var(--primary-text-color);
  --oui-currency-card-input-text-color: var(--primary-text-color);
  --oui-currency-card-button-selected-color: var(--primary-text-color);
  --oui-currency-card-button-selected-bg: var(--secondary-color);
  --oui-header-text-color: var(--primary-text-color);
  --oui-header-tab-option-color: #919191;
  --oui-header-tab-option-selected-color: #46de8c;
  --oui-header-tab-option-stroke-color: #3f3f3f;
  --oui-header-tab-option-selected-stroke-color: #46de8c;
  --oui-skeleton-color: #767676;

  --width: 100%;
  --heigth: 100%;
  --padding-top: 1.25rem;
  --padding-rl: 35px;
  --padding-bottom: 1.125rem;
  --header-height: 65px;
  --header-tabs-height: 54px;
  font-family: var(--font-family);
  width: var(--width);
  min-width: 250px;
  height: var(--heigth);
  display: flex;
  flex-direction: column;
  direction: ltr;
  -webkit-tap-highlight-color: transparent;
  scrollbar-color: #dbdbdb transparent;
}

.bluey-theme {
  --primary-color: hsl(248, 99%, 55%);
  --secondary-color: #ffffff;
  --primary-text-color: #141519;
  --secondary-text-color: #6b6f80;
  --container-color: #ffffff;
  --card-color: #f6f7f9;
  --border-radius: 0.5rem;
  --widget-border-radius: 1rem;
  --font-family: "Inter", sans-serif;

  /* widget styles */
  --background-color: var(--container-color);
  --overlay-backdrop-color: rgba(15, 10, 36, 0.4);
  --drawer-bg-color: var(--container-color);
  --onramp-info-bg-color: var(--card-color);
  --onramp-info-border-color: #bbbfcc;
  --onramp-info-section-bg: var(--card-color);
  --onramp-info-section-error-color: #e62e2e;

  /* oui component styles */
  --oui-btn-primary-border-radius: var(--border-radius);
  --oui-btn-secondary-border-radius: 3rem;
  --oui-btn-primary-color: #ffffff;
  --oui-btn-primary-bg: hsl(248, 99%, 55%);
  --oui-btn-primary-bg-hover: #3619e6;
  --oui-btn-primary-disabled-color: #9ea2b3;
  --oui-btn-primary-disabled-bg: #edeef2;
  --oui-btn-secondary-color: var(--primary-text-color);
  --oui-btn-secondary-bg: #ffffff;
  --oui-btn-secondary-disabled-color: #9ea2b3;
  --oui-btn-secondary-disabled-bg: #edeef2;
  --oui-btn-secondary-border-color: #e6dcdc;
  --oui-btn-secondary-border-color-hover: #9ea2b3;
  --oui-icon-btn-color: #141519;
  --oui-icon-btn-border-color: #e6dcdc;
  --oui-icon-btn-border-radius: var(--border-radius);
  --oui-icon-btn-selected-border-color: var(--primary-color);
  --oui-input-text-color: #141519;
  --oui-input-border-color: #bbbfcc;
  --oui-input-border-radius: var(--border-radius);
  --oui-input-error-border-color: #e62e2e;
  --oui-input-hover-border-color: #838799;
  --oui-input-focus-border-color: var(--primary-color);
  --oui-banner-success-bg: #e6fff3;
  --oui-banner-danger-bg: #ffe6e6;
  --oui-banner-title-color: #141519;
  --oui-banner-body-color: #6b6f80;
  --oui-menu-bg: var(--background-color);
  --oui-menu-color: #141519;
  --oui-menu-border-radius: var(--widget-border-radius);
  --oui-switch-color: #ffffff;
  --oui-switch-on-bg-color: hsl(248, 99%, 55%);
  --oui-switch-off-bg-color: #bbbfcc;
  --oui-tooltip-color: #bbbfcc;
  --oui-tooltip-bg-color: hsl(248, 99%, 55%);
  --oui-list-item-hover-color: #fafaff;
  --oui-list-item-rounded-border-color: #edeef2;
  --oui-list-item-rounded-border-hover-color: #bbbfcc;
  --oui-list-item-rounded-border-selected-color: var(--primary-color);
  --oui-list-item-top-label-color: var(--primary-color);
  --oui-list-item-primary-text-color: #141519;
  --oui-list-item-secondary-text-color: #6b6f80;
  --oui-list-item-tertiary-text-color: #6b6f80;
  --oui-list-item-top-label-color: var(--primary-color);
  --list-item-top-label-text-color: #ffffff;
  --oui-currency-card-bg: var(--card-color);
  --oui-currency-card-border-radius: var(--border-radius);
  --oui-currency-card-hover-border-color: #bbbfcc;
  --oui-currency-card-label-text-color: var(--secondary-text-color);
  --oui-currency-card-input-text-color: #141519;
  --oui-currency-card-button-selected-color: var(--primary-text-color);
  --oui-currency-card-button-selected-bg: var(--secondary-color);
  --oui-header-text-color: #141519;
  --oui-header-tab-option-color: #141519;
  --oui-header-tab-option-selected-color: #141519;
  --oui-header-tab-option-stroke-color: #edeef2;
  --oui-header-tab-option-selected-stroke-color: #3b1cfe;
  --oui-skeleton-color: #c2cfd6;
}

.sushi-theme {
  --primary-color: #3b7ef6;
  --secondary-color: #364154;
  --primary-text-color: #f9fafd;
  --secondary-text-color: #cdd5e0;
  --container-color: #101728;
  --card-color: #212733;
  --border-radius: 0.5rem;
  --widget-border-radius: 1rem;
  --font-family: "Inter", sans-serif;

  /* widget styles */
  --background-color: var(--container-color);
  --overlay-backdrop-color: rgba(0, 0, 0, 0.7);
  --drawer-bg-color: var(--container-color);
  --onramp-info-bg-color: var(--card-color);
  --onramp-info-border-color: #767676;
  --onramp-info-section-bg: var(--card-color);
  --onramp-info-section-error-color: #e62e2e;

  /* oui component styles */
  --oui-btn-primary-border-radius: var(--border-radius);
  --oui-btn-secondary-border-radius: 3rem;
  --oui-btn-primary-color: var(--primary-text-color);
  --oui-btn-primary-bg: hsl(219, 91%, 60%);
  --oui-btn-primary-bg-hover: hsl(219, 91%, 56%);
  --oui-btn-primary-disabled-color: #767676;
  --oui-btn-primary-disabled-bg: var(--card-color);
  --oui-btn-secondary-color: #131316;
  --oui-btn-secondary-bg: #ffffff;
  --oui-btn-secondary-disabled-color: #9ea2b3;
  --oui-btn-secondary-disabled-bg: #edeef2;
  --oui-btn-secondary-border-color: #e6dcdc;
  --oui-btn-secondary-border-color-hover: #767676;
  --oui-icon-btn-color: var(--primary-text-color);
  --oui-icon-btn-border-color: #767676;
  --oui-icon-btn-border-radius: var(--border-radius);
  --oui-icon-btn-selected-border-color: var(--primary-color);
  --oui-input-text-color: var(--primary-text-color);
  --oui-input-border-radius: var(--border-radius);
  --oui-input-border-color: #bbbfcc;
  --oui-input-error-border-color: #e62e2e;
  --oui-input-hover-border-color: #838799;
  --oui-input-focus-border-color: var(--primary-color);
  --oui-banner-success-bg: #e6fff3;
  --oui-banner-danger-bg: #ffe6e6;
  --oui-banner-title-color: #141519;
  --oui-banner-body-color: #6b6f80;
  --oui-menu-bg: var(--background-color);
  --oui-menu-color: var(--primary-text-color);
  --oui-menu-border-radius: var(--widget-border-radius);
  --oui-switch-color: #ffffff;
  --oui-switch-on-bg-color: hsl(219, 91%, 60%);
  --oui-switch-off-bg-color: #bbbfcc;
  --oui-tooltip-color: #bbbfcc;
  --oui-tooltip-bg-color: hsl(219, 91%, 60%);
  --oui-list-item-hover-color: var(--card-color);
  --oui-list-item-rounded-border-color: #767676;
  --oui-list-item-rounded-border-hover-color: #bbbfcc;
  --oui-list-item-rounded-border-selected-color: var(--primary-color);
  --oui-list-item-primary-text-color: var(--primary-text-color);
  --oui-list-item-secondary-text-color: var(--primary-text-color);
  --oui-list-item-tertiary-text-color: var(--primary-text-color);
  --oui-list-item-top-label-color: var(--primary-color);
  --oui-currency-card-bg: var(--card-color);
  --oui-currency-card-border-radius: var(--border-radius);
  --oui-currency-card-hover-border-color: #767676;
  --oui-currency-card-label-text-color: #97a3b7;
  --oui-currency-card-input-text-color: var(--primary-text-color);
  --oui-currency-card-button-selected-color: var(--primary-text-color);
  --oui-currency-card-button-selected-bg: var(--secondary-color);
  --oui-header-text-color: var(--primary-text-color);
  --oui-header-tab-option-color: #919191;
  --oui-header-tab-option-selected-color: ##3b7ef6;
  --oui-header-tab-option-stroke-color: #3f3f3f;
  --oui-header-tab-option-selected-stroke-color: #3b7ef6;
  --oui-skeleton-color: #767676;

  --width: 100%;
  --heigth: 100%;
  --padding-top: 1.25rem;
  --padding-rl: 35px;
  --padding-bottom: 1.125rem;
  --header-height: 65px;
  --header-tabs-height: 54px;
  font-family: var(--font-family);
  width: var(--width);
  min-width: 250px;
  height: var(--heigth);
  display: flex;
  flex-direction: column;
  direction: ltr;
  -webkit-tap-highlight-color: transparent;
  scrollbar-color: #dbdbdb transparent;
}

@media (max-width: 575px), (max-height: 615px) {
  [class*="theme"] {
    --widget-border-radius: 0 !important;
  }
}

.theme {
  --widget-height: 33.75rem;
  --widget-width: 22.5rem;
  --bottom-drawer-max-height: 95%;
  --width: 100%;
  --heigth: 100%;
  --padding-top: 1.25rem;
  --padding-rl: 35px;
  --padding-bottom: 1.125rem;
  /* --pt-heading: 12px;
    --pb-heading: 35px; */
  /* header */
  --header-height: 65px;
  --header-tabs-height: 54px;
  font-family: var(--font-family);
  width: var(--width);
  min-width: 250px;
  height: var(--heigth);
  display: flex;
  flex-direction: column;
  direction: ltr;
  -webkit-tap-highlight-color: transparent;
  scrollbar-color: #dbdbdb transparent;
}

.theme ::-webkit-scrollbar {
  width: 10px;
}

.theme ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #dbdbdb;
}

.theme ::-webkit-scrollbar-track {
  background-color: transparent;
}

.theme input[type="number"] {
  -moz-appearance: textfield;
}

.theme input[type="number"]::-webkit-outer-spin-button,
.theme input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.theme input {
  caret-color: var(--primary-color);
}

.theme nav {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

.theme.dark {
  filter: invert(1) hue-rotate(180deg) opacity(0.8) contrast(0.95);
}
