html,
body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent;
  font-size: 1rem;

  /* temp styles*/

  /* background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 0.52),
      rgb(255, 255, 255)
    ),
    url("./images/background.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom; */
}

html,
body,
#root {
  height: 100%;
}

.widget-container {
  max-width: 420px;
  max-height: 630px;
  height: 100%;
  width: 100%;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: var(--widget-border-radius, 1rem);
  margin: auto;
}

/*
new classes for onramper.com theme
*/

.header {
  display: flex;
  justify-content: space-between;
  padding: 32px 40px 0px 40px;
}

.container {
  position: absolute;
  width: 100%;
}

.learn-more-link {
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: var(--oui-btn-primary-bg);
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

@media (max-width: 575px), (max-height: 615px) {
  .widget-container {
    max-width: 100%;
    max-height: 100%;
    border-radius: 0 !important;
  }
}

@media (max-width: 1000px), (max-height: 840px) {
  .header {
    display: none;
  }
}
